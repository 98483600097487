import {
  MEDICATION_OVERVIEW_PAGE_ROUTE_NAME,
  MEDICATION_PAGE_ROUTE_NAME
} from './routes'

const MedicationOverviewPage = () => import('@/pages/medication/MedicationOverview')
const MedicationPage = () => import('@/pages/medication/MedicationPage')

export default [
  {
    path: '/medication',
    name: MEDICATION_PAGE_ROUTE_NAME,
    component: MedicationPage,
    children: [
      {
        path: 'medication-overview/',
        name: MEDICATION_OVERVIEW_PAGE_ROUTE_NAME,
        component: MedicationOverviewPage
      }
    ]
  }
]
