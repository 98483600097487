import { _get, _save } from '@/communication/api'

const URL_BASE_STAFF = 'backoffice/api/v1/staff-members'
const URL_BASE_LOGIN_STAFF = 'login'
const URL_BASE_LOGIN_STAFF_WITH_FINGERPRINT = 'login-fingerprint'

/**
 * Get the staff members.
 * @return {Promise} - A promise with staff members
 */

export function getStaffMembers () {
  const url = `${URL_BASE_STAFF}/`
  return _get(url)
}

/**
 * Get a staff member.
 * @param {Number} id - The id of the staff member.
 * @return {Promise} - A promise with the staff member
 */

export function getStaffMember (id) {
  const url = `${URL_BASE_STAFF}/${id}/`
  return _get(url)
}

/**
 * Save a staff member.
 * @param {Object} data - The staff member data.
 * @param {String} data.name - The name of the staff member
 * @return {Promise} - A promise with the staff member
 */

export function saveStaffMember ({ name }) {
  const url = `${URL_BASE_STAFF}/`
  return _save(url, { name }, true)
}

/** Login a staff member.
* @param {Object} data - The staff credentials data.
* @param {String} data.email - The email of the staff member
* @param {String} data.password - The password of the staff member
* @return {Promise} - A promise with the response of the login
*/

export function loginStaffMember ({ email, password }) {
  const url = URL_BASE_LOGIN_STAFF
  return _save(url, { email, password }, true, false)
}

/** Login a staff member with fingerprint.
* @param {Object} data - The staff credentials data.
* @param {String} data.sensorId - The id of the sensor
* @param {String} data.fingerprintBase64 -  The image in Base64
* @return {Promise} - A promise with the response of the login
*/

export function loginStaffMemberWithFingerPrint ({ sensorId, fingerprintBase64 }) {
  const url = URL_BASE_LOGIN_STAFF_WITH_FINGERPRINT
  return _save(url, { sensorId, fingerprintBase64 }, true, false)
}
