import {
  SENSOR_OVERVIEW_PAGE_ROUTE_NAME,
  SENSOR_PAGE_ROUTE_NAME
} from './routes'

const SensorOverviewPage = () => import('@/pages/sensor/SensorOverviewPage')
const SensorPage = () => import('@/pages/sensor/SensorPage')

export default [
  {
    path: '/sensor',
    name: SENSOR_PAGE_ROUTE_NAME,
    component: SensorPage,
    children: [
      {
        path: 'sensor-overview/',
        name: SENSOR_OVERVIEW_PAGE_ROUTE_NAME,
        component: SensorOverviewPage
      }
    ]
  }
]
