import {
  getStaffMember,
  getStaffMembers,
  loginStaffMember,
  loginStaffMemberWithFingerPrint
} from '@/communication/bundles_min'

const stateObj = {
  staffMembers: [],
  loggedStaff: null
}

const getters = {
  getAllStaffMembers (state) {
    return state.staffMembers
  },
  getLoggedStaff (state) {
    return state.loggedStaff
  }
}
const mutations = {
  setStaffMember (state, value) {
    state.loggedStaff = value
  },
  setAllStaffMembers (state, value) {
    state.staffMembers = value
  }
}

const actions = {
  fetchStaffMember ({ commit }, payload) {
    getStaffMember(payload)
      .then(response => {
        return response
      })
      .catch(error => {
        throw Error(error)
      })
  },
  fetchStaffMembers ({ commit }) {
    getStaffMembers()
      .then(response => {
        commit('setAllStaffMembers', response)
        return response
      })
      .catch(error => {
        throw Error(error)
      })
  },
  loginStaff ({ commit }, payload) {
    return loginStaffMember(payload)
      .then((response) => {
        commit('setStaffMember', response)
        return response
      })
      .catch(error => {
        throw Error(`${error.name}` + ': Staff is not registered in the system')
      })
  },
  loginStaffWithFingerPrint ({ commit }, payload) {
    return loginStaffMemberWithFingerPrint(payload)
      .then((response) => {
        commit('setStaffMember', response)
        return response
      })
      .catch(error => {
        throw Error(`${error.name}` + ': Staff is not registered in the system')
      })
  }
}

export default {
  actions,
  getters,
  mutations,
  state: stateObj
}
