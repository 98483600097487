<template>
  <header
    class="he-ui-tabs-container">
    <div class="he-ui-bar-icon d-flex align-items-center ms-2 me-3">
      <img src="../assets/logo-heuristik.png" alt="logo_heuristik" />
    </div>
    <div class="d-flex w-100 position-relative justify-content-between me-4">
      <div class="d-flex flex-row justify-content-start">
        <div
          v-for="(item, index) in tabs.filter(item => !item.disabled)"
          :id="item.id"
          :key="item.id"
          :class="['he-ui-tabs-container-tab-item px-lg-4 px-xl-2 d-flex justify-content-center align-items-center', {'active': index === activeTabIndex }]">
          <div
            class="he-ui-tabs-container-tab-item-link d-flex justify-content-between align-items-center h-100 text-center"
            :ref="el => { if (el) itemTabs[index] = el }"
            @click.stop="selectTab($event, index)"
            @mouseover.stop.prevent="mouseOver"
            @mouseout.stop.prevent="mouseOut">
            <div class="d-flex justify-content-center align-items-center w-100">
              <span>{{ $t(item.text) }}</span>
            </div>
            <!-- <span
              class="material-icons-outlined he-ui-tabs-container-tab-item-icon">{{ item.icon }}</span> -->
              <span class="he-ui-tabs-container-tab-item-icon">
                <img :src="item.icon" />
              </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <user-role-identificator-vue />
        <div
          class="he-ui-tabs-container-tab-close-session p-3"
          @click="onCloseSession">
          <!-- <span
              class="material-icons-outlined close-session-icon">exit_to_app</span> -->
              <span class="close-session-icon">
                <img :src="this.iconCloseSession" />
              </span>
          <span class="ms-2">{{ $t('main.close_session') }}</span>
        </div>
        <!-- <div
          ref="itemMark"
          class="he-ui-tabs-container-tab-mark" /> -->
        </div>
      </div>
  </header>
</template>

<script>
import {
  computed,
  onMounted,
  ref
} from 'vue'
import UserRoleIdentificatorVue from '@/components/UserRoleIdentificator.vue'
import imgCloseSession from '@/assets/sign-out.svg'

const PX_STR = 'px'

export default {
  emits: ['update:modelValue', 'closeSession'],
  components: {
    UserRoleIdentificatorVue
  },
  props: {
    tabs: {
      type: Array,
      validator: value => {
        return value.length != null
      }
    },
    modelValue: {
      type: [String, Number, Array],
      default: 0
    }
  },
  setup (props, context) {
    const iconCloseSession = imgCloseSession
    const activeTabIndex = ref(props.value)
    const clicked = ref(false)
    const initialLeft = ref(null)
    const initialWidth = ref(null)

    const itemMark = ref()
    const itemTabs = ref([])

    const selectedTabData = computed(() => {
      return props.tabs[activeTabIndex.value] && props.tabs[activeTabIndex.value].data
    })

    onMounted(() => {
      setTimeout(() => {
        if (itemTabs.value[0]) selectFirstTab()
      }, 1000)
    })

    function selectFirstTab () {
      activeTabIndex.value = 0
    }

    function onClickItem (e) {
      if (e) {
        clicked.value = true
        const target = e.target || e
        if (itemMark.value) {
          itemMark.value.style.left = target.offsetLeft + PX_STR
          itemMark.value.style.width = target.offsetWidth + PX_STR
          initialLeft.value = target.offsetLeft
          initialWidth.value = target.offsetWidth
        }
      }
    }

    function mouseOver (e) {
      if (itemMark.value) {
        itemMark.value.style.width = e.target.offsetWidth + PX_STR
        itemMark.value.style.left = e.target.offsetLeft + PX_STR
      }
    }

    function mouseOut (e) {
      if (itemMark.value) {
        itemMark.value.style.left = initialLeft.value + PX_STR
        itemMark.value.style.width = initialWidth.value + PX_STR
      }
    }

    function selectTab (e, tabIndex) {
      if (props.tabs.length === 0) return
      activeTabIndex.value = tabIndex
      onClickItem(e)
      context.emit('update:modelValue', tabIndex)
    }

    function onCloseSession () {
      context.emit('closeSession')
    }

    return {
      activeTabIndex,
      iconCloseSession,
      itemMark,
      itemTabs,
      clicked,
      mouseOver,
      mouseOut,
      initialLeft,
      initialWidth,
      onCloseSession,
      selectTab,
      selectedTabData
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-tabs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
  border-radius: 14px;
  background: $white-color;
}
.he-ui-bar-icon {
  width: 3rem;
  height: 3rem;
  padding: 6px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  img {
      width: 100%;
    }
}
.he-ui-tabs-container-tab-item {
  cursor: pointer;
  height: 3rem;

  &.active {
    .he-ui-tabs-container-tab-item-link {
        font-weight: bold;
        color: $blue-color;
        // background: $background-app-color;
        background: $blue-color-8-percent;
    }
    .he-ui-tabs-container-tab-item-icon {
      background: $blue-color;
    }
  }

  .he-ui-tabs-container-tab-item-link {
    user-select: none;
    font-weight: 500;
    color: $black-color;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 1rem;
    background: $background-app-color;
    border-radius: 14px;
    width: 10.25rem;
  }
  .he-ui-tabs-container-tab-item-icon {
    color: white;
    background: $black-color;
    padding: .2rem .3rem;
    border-radius: 6px;
    font-size: .7rem;
    margin-left: .4rem;
  }
}

.he-ui-tabs-container-tab-close-session {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $cement-color;
  border: 2px solid $background-app-color;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  font-size: 0.75rem;
  font-weight: 700;
  height: 3rem;
  .close-session-icon {
    padding: .3rem;
    filter: invert(46%) sepia(2%) saturate(548%) hue-rotate(314deg) brightness(92%) contrast(83%); // $cement-color #736F6F
  }
}

// .he-ui-tabs-container-tab-mark {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   height: 0.13rem;
//   background: $blue-color;
//   transition-property: left, width;
//   transition-duration: .4s;
//   transition-timing-function: linear;
// }

@media (max-width: 550px) {
  .he-ui-tabs-container-tab-item,
  .he-ui-tabs-container-tab-close-session {
    font-size: 0.7rem;
  }
}
</style>
