import { _get } from '@/communication/api'

const URL_BASE_SENSOR = 'backoffice/api/v1/sensors'

/**
 * Get the registered sensors.
 * @return {Promise} - A promise with sensors
 */

export function getSensors () {
  const url = `${URL_BASE_SENSOR}`
  return _get(url)
}
