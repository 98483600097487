import {
  LOCATION_OVERVIEW_PAGE_ROUTE_NAME,
  LOCATION_PAGE_ROUTE_NAME
} from './routes'

const LocationOverviewPage = () => import('@/pages/location/LocationOverviewPage')
const LocationPage = () => import('@/pages/location/LocationPage')

export default [
  {
    path: '/location',
    name: LOCATION_PAGE_ROUTE_NAME,
    component: LocationPage,
    children: [
      {
        path: 'location-overview/',
        name: LOCATION_OVERVIEW_PAGE_ROUTE_NAME,
        component: LocationOverviewPage
      }
    ]
  }
]
