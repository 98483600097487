import 'mutationobserver-shim'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { datadogRum } from '@datadog/browser-rum'
import App from './App.vue'
import auth from '@/communication/auth'
import store from '@/store/store'

import 'material-icons/iconfont/material-icons.css'

import router from './router'

const en = require('./locales/en.json')
const es = require('./locales/es.json')
const pt = require('./locales/pt.json')

const AVAILABLE_LANGUAGES = { es: 'es', en: 'en', pt: 'pt' }

function getDefaultUserLanguage () {
  if (window.navigator.languages) {
    return window.navigator.languages[0]
  }
  return window.navigator.language
}

const USER_DEFAULT_LANGUAGE = getDefaultUserLanguage().split('-')[0] as keyof typeof AVAILABLE_LANGUAGES

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: AVAILABLE_LANGUAGES[USER_DEFAULT_LANGUAGE] ? AVAILABLE_LANGUAGES[USER_DEFAULT_LANGUAGE] : 'en',
  messages: {
    en: en,
    es: es,
    pt: pt
  }
})

// Datadog
if (process.env.VUE_APP_ENVIRONMENT === 'PRODUCTION') {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_APP_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'heuristik-client',
    env: 'prod',
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [process.env.VUE_APP_WS_URL]
  })

  // const isLoggedIn = isUserLoggedIn();
  const isLoggedIn = auth.checkAuth()

  if (isLoggedIn) {
    datadogRum.startSessionReplayRecording()

    const userData = auth.getUserData()
    datadogRum.setUser({
      id: userData.id,
      name: userData.name + ' ' + userData.surname,
      email: userData.email,
      tenantId: userData.tenantId
    })
  }
}

const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')
