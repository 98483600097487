import auth from '@/communication/auth'
import axios from 'axios'
import Bowser from 'bowser'
import getUserCountry from 'js-user-country'

const API_URL = process.env.VUE_APP_WS_URL

const HEADER_LANGUAGE_NAME = 'language'
const HEADER_SUBLANGUAGE_NAME = 'sublanguage'
const HEADER_TIMEZONE_NAME = 'timezone'
const HEADER_PLATFORM_NAME = 'platform'
const HEADER_COUNTRY_NAME = 'country'
const HEADER_BRAND_BROWSER_NAME = 'brand'
const HEADER_SO_NAME = 'so'

const browserData = Bowser.getParser(window.navigator.userAgent)

const LANGUAGE_VALUE = navigator.language.split("-")[0]
const SUBLANGUAGE_VALUE = navigator.language.split("-")[1] === undefined ? "" : navigator.language.split("-")[1]
const TIMEZONE_VALUE = Intl.DateTimeFormat().resolvedOptions().timeZone
const PLATFORM_VALUE = 'Web'
const COUNTRY_VALUE = getUserCountry().id
const BRAND_BROWSER_VALUE = `${browserData.getBrowserName()} ${browserData.getBrowserVersion()}`
const SO_VALUE = `${browserData.getOSName()} ${browserData.getOSVersion()}`

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common[HEADER_LANGUAGE_NAME] = LANGUAGE_VALUE
axios.defaults.headers.common[HEADER_SUBLANGUAGE_NAME] = SUBLANGUAGE_VALUE
axios.defaults.headers.common[HEADER_TIMEZONE_NAME] = TIMEZONE_VALUE
axios.defaults.headers.common[HEADER_PLATFORM_NAME] = PLATFORM_VALUE
axios.defaults.headers.common[HEADER_COUNTRY_NAME] = COUNTRY_VALUE
axios.defaults.headers.common[HEADER_BRAND_BROWSER_NAME] = BRAND_BROWSER_VALUE
axios.defaults.headers.common[HEADER_SO_NAME] = SO_VALUE


export function _save (url, data, isNew, verifyUnauthorized = true) {
  const restVerb = isNew ? 'post' : 'put'
  const config = _getHeaders()
  return new Promise((resolve, reject) => {
    axios[restVerb](`${API_URL}/${url}`, data, config)
      .then((response) => {
        resolve(response.data)
      })
      .catch(e => {
        if (verifyUnauthorized) auth.isUnauthorized(e)
        reject(e)
      })
  })
}

export function _patch (url, data) {
  return new Promise((resolve, reject) => {
    axios.patch(`${API_URL}/${url}`, data, _getHeaders)
      .then((response) => {
        resolve(response.body)
      })
      .catch(e => {
        auth.isUnauthorized(e)
        reject(e)
      })
  })
}

export function _delete (url, data = null) {
  return new Promise((resolve, reject) => {
    const config = _getHeaders()
    if (data) {
      config.body = data
    }
    axios.delete(`${API_URL}/${url}`, config)
      .then((response) => {
        resolve(response.body)
      })
      .catch(e => {
        auth.isUnauthorized(e)
        reject(e)
      })
  })
}

export function _get (url) {
  return new Promise((resolve, reject) => {
    const config = _getHeaders()
    axios.get(`${API_URL}/${url}`, config)
      .then(response => {
        resolve(response.data)
      })
      .catch(e => {
        auth.isUnauthorized(e)
        reject(e)
      })
  })
}

export function _getHeaders ({
  includeToken
} = {}) {
  const headers = {}
  if (includeToken ?? true) {
    headers.Authorization = auth.getAuthHeader()
  }
  return {
    headers: headers
  }
}
