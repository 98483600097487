export const MAIN_PAGE_ROUTE_NAME = 'home'
export const NOT_FOUND_PAGE_ROUTE_NAME = 'not-found'
export const SCAN_FINGERPRINT_PAGE_ROUTE_NAME = 'scanfingerprint'
export const SCAN_MULTIFINGER_PAGE_ROUTE_NAME = 'scan-fingers'
export const STORED_FINGERPRINT_PAGE_ROUTE_NAME = 'stored-fingerprints'

export const STATISTICS_OVERVIEW_PAGE_ROUTE_NAME = 'statistics-overview'
export const STATISTICS_DETAILS_PAGE_ROUTE_NAME = 'statistics-details'
export const STATISTICS_PAGE_ROUTE_NAME = 'statistics'

export const LOCATION_PAGE_ROUTE_NAME = 'location'
export const LOCATION_OVERVIEW_PAGE_ROUTE_NAME = 'location-overview'

export const MEDICATION_PAGE_ROUTE_NAME = 'medication'
export const MEDICATION_OVERVIEW_PAGE_ROUTE_NAME = 'medication-overview'

export const SENSOR_OVERVIEW_PAGE_ROUTE_NAME = 'sensor-overview'
export const SENSOR_PAGE_ROUTE_NAME = 'sensor'
