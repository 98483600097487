
import {
  CommunicationBreakEvent,
  DriverDisconnectedEvent,
  NewDeviceEvent,
  PreviewImageEvent,
  ResultImageEvent,
  WebDriver
} from '@/communication/bundles_min'
import { onMounted, ref } from 'vue'

const BASE_64_HEADER = 'data:image/bmp;base64'

export default () => {
  const imageData = ref(null)
  const hasResultImage = ref(null)
  const showSensorNotFoundModal = ref(false)
  const showWebSocketDisconnectedModal = ref(false)
  const isLowQuality = ref(false)
  const driver = WebDriver.connect()

  onMounted(() => {
    driver.on(PreviewImageEvent, (event) => {
      imageData.value = `${BASE_64_HEADER}, ` + event.data
    })
    driver.on(CommunicationBreakEvent, () => {
      showSensorNotFoundModal.value = true
    })
    driver.on(NewDeviceEvent, () => {
      showSensorNotFoundModal.value = false
    })
    // ResultImage necessary for capture image and get score for detect LowQuality fingerprints
    driver.on(ResultImageEvent, (event) => {
      imageData.value = `${BASE_64_HEADER}, ` + event.data
      hasResultImage.value = true
      isLowQuality.value = event.score <= process.env.VUE_APP_LOW_QUALITY_LIMIT
    })
    driver.on(DriverDisconnectedEvent, (event) => {
      console.log('WebSocket disconnection')
      showWebSocketDisconnectedModal.value = true
    })
  })

  const captureFingerprint = () => {
    // reset
    imageData.value = null
    hasResultImage.value = false
    isLowQuality.value = false
    driver.capture()
    console.log('capturing..')
  }

  const stopCapture = () => {
    driver.stopCapture()
  }

  const clean = (data) => {
    return data.replace(`${BASE_64_HEADER}, `, '')
  }

  const resetFingerprint = () => {
    imageData.value = null
    hasResultImage.value = false
    isLowQuality.value = false
  }

  const getDeviceCount = async () => {
    showSensorNotFoundModal.value = true
    await driver.get_device_count()
    showSensorNotFoundModal.value = false
  }

  return {
    imageData,
    clean,
    captureFingerprint,
    getDeviceCount,
    hasResultImage,
    isLowQuality,
    resetFingerprint,
    showSensorNotFoundModal,
    showWebSocketDisconnectedModal,
    stopCapture
  }
}
