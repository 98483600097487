import {
  MAIN_PAGE_ROUTE_NAME,
  NOT_FOUND_PAGE_ROUTE_NAME,
  SCAN_FINGERPRINT_PAGE_ROUTE_NAME,
  SCAN_MULTIFINGER_PAGE_ROUTE_NAME,
  STORED_FINGERPRINT_PAGE_ROUTE_NAME
} from './routes'
import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/communication/auth'
import locationRoutes from './location'
import medicationRoutes from './medication'
import sensorRoutes from './sensor'
import statisticsRoutes from './statistics'
const DefaultScanning = () => import('@/pages/DefaultScanning')
const Identify = () => import('@/pages/IdentifyPage')
const PageNotFound = () => import('@/pages/PageNotFound')
const ScanMultipleFingersPage = () => import('@/pages/ScanMultipleFingersPage')
const StoredFingerprintsPage = () => import('@/pages/StoredFingerprintsPage')

export { statisticsRoutes }
const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: NOT_FOUND_PAGE_ROUTE_NAME,
    component: PageNotFound
  },
  {
    path: '/',
    name: MAIN_PAGE_ROUTE_NAME,
    component: Identify
  },
  {
    path: '/scanfingerprint',
    name: SCAN_FINGERPRINT_PAGE_ROUTE_NAME,
    component: DefaultScanning
  },
  {
    path: '/scan-fingers',
    name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME,
    component: ScanMultipleFingersPage
  },
  {
    path: '/stored-fingerprints',
    name: STORED_FINGERPRINT_PAGE_ROUTE_NAME,
    component: StoredFingerprintsPage
  }
]
statisticsRoutes.forEach(item => {
  routes.push(item)
})

locationRoutes.forEach(item => {
  routes.push(item)
})

medicationRoutes.forEach(item => {
  routes.push(item)
})

sensorRoutes.forEach(item => {
  routes.push(item)
})

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!auth.checkAuth() && ![MAIN_PAGE_ROUTE_NAME].includes(to.name)) return next({ name: MAIN_PAGE_ROUTE_NAME })
  else next()
})

export default router
