<template>
  <modal v-on="$attrs">
    <template #body>
      <p class="he-ui-error-modal-title text-uppercase">{{ title }}</p>
      <p class="he-ui-error-modal-subtitle">{{ subtitle }}</p>
      <div class="he-ui-error-modal-image mb-2">
        <img
          :src="imageSrc"
          alt="image-error" />
      </div>
      <div class="d-flex gap-3">
        <Button
          v-for="button, index in buttons"
          :key="index"
          class="mt-4"
          :template="button.template"
          rounded
          uppercase
          :text="button.text"
          :onClick="button.handler" />
      </div>
    </template>
  </modal>
</template>

<script>
import Button from '@/components/MainButton'
import Modal from './Modal.vue'

export default {
  components: {
    Button,
    Modal
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    buttons: {
      type: Array,
      required: true
    }
  },
  computed: {
    imageSrc () {
      return require(`../assets/${this.image}.png`)
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-error-modal-title {
  font-weight: bold;
  font-size: 1.4rem;
}
.he-ui-error-modal-subtitle {
  font-size: 1.1rem;
  color: $grey-dark-color;
}
.he-ui-error-modal-image{
  width: 140px;
  height: 140px;
   img {
    max-width: 100%;
   }
}
</style>
