import {
  getSensors
} from '@/communication/bundles_min'

const stateObj = {
  sensors: []
}

const getters = {
  getAllSensors (state) {
    return state.sensors
  }
}
const mutations = {
  setSensors (state, value) {
    state.sensors = value
  }
}

const actions = {
  fetchSensors ({ commit }, payload) {
    getSensors(payload)
      .then(response => {
        commit('setSensors', response)
        return response
      })
      .catch(error => {
        throw Error(error)
      })
  }
}

export default {
  actions,
  getters,
  mutations,
  state: stateObj
}
