import { createStore } from 'vuex'
import patient from './patient'
import sensor from './sensor'
import staff from './staff'

const store = createStore({
  modules: {
    patient,
    sensor,
    staff
  }
})

export default store
