<template>
  <!-- Temporarily hidden -->
  <div
    v-if='this.showComponent'
    class="he-ui-user-role-identificator"
    :class="this.userRole"
  >
    <!-- @TODO svg conditionally -->
    <div>{{ userName }}</div>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  ref
} from 'vue'
export default {
  setup () {
    const showComponent = computed(() => {
      return userName.value !== '' && userRole.value !== ''
    })

    const userName = ref()
    const userRole = ref()

    onMounted(() => {
      userName.value = ''
      userRole.value = 'SANITARY' // @MOCK
    })

    return {
      showComponent,
      userName,
      userRole
    }
  }
}
</script>
<style lang="scss" scoped>
  .he-ui-user-role-identificator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 21px 12px 12px;
    margin-right: 9px;
    border-radius: 10px;
  }
  .ADMINISTRATOR {
    background: rgba(215, 215, 215, 0.35);
    color: #939393;
  }
  .SANITARY {
    background: rgba(54, 138, 255, 0.14);
    color: #368AFF;
  }
  .BOSS {
    background: rgba(43, 49, 133, 0.15);
    color: #2B3185;
  }
</style>
