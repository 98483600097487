<template>
  <modal v-on="$attrs">
    <template #body>
      <p class="he-ui-sensor-modal-title text-uppercase">{{ title }}</p>
      <p class="he-ui-sensor-modal-subtitle">{{ subtitle }}</p>
      <div class="he-ui-sensor-modal-image mb-2">
        <img
          :src="imageSrc"
          alt="image-error" />
      </div>
      <Button
        v-if="button"
        class="mt-4"
        template="blue"
        rounded
        uppercase
        :text="button.text"
        :onClick="button.handler" />
    </template>
  </modal>
</template>

<script>
import Button from '@/components/MainButton'
import Modal from './Modal.vue'

export default {
  components: {
    Button,
    Modal
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageSrc () {
      return require(`../assets/${this.image}.png`)
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-sensor-modal-title {
  font-weight: bold;
  font-size: 1.4rem;
}
.he-ui-sensor-modal-subtitle {
  font-size: 1.1rem;
  color: $grey-dark-color;
}
.he-ui-sensor-modal-image{
  width: 140px;
  height: 140px;
   img {
    max-width: 100%;
   }
}
</style>
