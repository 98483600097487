import { _delete, _get, _save } from '@/communication/api'

const URL_BASE_LOGIN = 'app/api/v1/patients/login'
const URL_BASE_PATIENTS = 'app/api/v1/patients'

const URL_BASE_FINGERPRINTS = 'app/api/v1/patients/{patientId}/fingerprints'
const regexp = new RegExp('\\{patientId\\}', 'gi')

/**
 * Make a login for a patient.
 * @param {Object} data - The login data.
 * @param {String} data.sensorId - The id of the sensor
 * @param {String} data.fingerprintBase64 - The image in Base64
 * @param {Number} data.fingerNumber - The number of the finger (0 to 9)
 * @return {Promise} - A promise with server response
 */

export function login ({ sensorId, fingerprintBase64, fingerNumber}) {
  const url = `${URL_BASE_LOGIN}/`
  return _save(url, { sensorId, fingerprintBase64, fingerNumber }, true)
}

/**
 * Register a new patient.
 * @return {Promise} - A promise with new patient
 */

export function addNewPatient () {
  const url = `${URL_BASE_PATIENTS}/`
  return _save(url, null, true)
}

/** Register a fingerprint.
* @param {Object} data - The staff member data.
* @param {String} data.patientId - The id of the patient
* @param {String} data.sensorId - The id of the sensor
* @param {String} data.fingerprintBase64 - The fingerprint in format BASE64
* @param {Number} data.fingerNumber - The number of the finger [0-9]
* @return {Promise} - A promise with the response of the login
*/

export function createNewFingerprint ({ patientId, sensorId, fingerprintBase64, fingerNumber }) {
  const url = URL_BASE_FINGERPRINTS.replace(regexp, patientId)
  return _save(url, { sensorId, fingerprintBase64, fingerNumber }, true)
}

/**
 * Get the saved fingerprints for a patient.
 * @param {Object} data - Data.
 * @param {String} data.patientId - The id of the patient
 * @return {Promise} - A promise with the saved fingerprints
 */

export function getPatientFingerprints ({ patientId }) {
  const url = URL_BASE_FINGERPRINTS.replace(regexp, patientId)
  return _get(url)
}

/**
 * Remove a patient.
 * @param {String} patientId - The id of the patient
 * @return {Promise} - A promise
 */

export function removePatient (patientId) {
  const url = `${URL_BASE_PATIENTS}/${patientId}/`
  return _delete(url)
}
