<template>
  <portal to="modal-place">
    <div class="he-ui-modal-mask">
      <div class="he-ui-modal-wrapper">
        <div
          v-bind="$attrs"
          class="he-ui-modal-container position-relative d-flex flex-column align-items-center">
          <slot name="body" />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.he-ui-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.he-ui-modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.he-ui-modal-container {
  // width: 700px;
  width: 859px;
  margin: 0px auto;
  padding: 60px 100px;
  background-color: #fff;
  border-radius: 26px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
}

</style>
