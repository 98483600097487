<template>
  <button
    :class="[
            {'he-ui-main-button-rounded': rounded},
            {'uppercase': uppercase},
            {'selected': isSelected},
            {'shadow': shadow},
            template]"
    class="he-ui-main-button"
    :disabled="disabled"
    @click.stop="onClickButton">
    <span>{{ text }}</span>
    <span
      v-if="icon"
      class="material-icons he-ui-main-button-icon">{{ icon }}</span>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    template: {
      type: String,
      default: ''
    },
    rounded: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickButton () {
      if (this.onClick) this.onClick()
    }
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-main-button {
    background: transparent;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 22px;
    border: none;
    cursor: pointer;
    padding: 1% 1.5rem;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
    outline: none;
    white-space: nowrap;
    min-width: 207px;
    min-height: 60px;

    &.uppercase {
      text-transform: uppercase;
      font-weight: 700;
    }

    &.he-ui-main-button-rounded {
      border-radius: 22px;
      padding: .5rem 2rem;
    }

    &.shadow {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
    }

    &.blue {
      background-color: $blue-color;
    }
    &.black {
      background-color: $black-color;
    }
    &.white {
      background-color: white;
      color: $blue-color;
      border: 4px solid $blue-color;
      &.selected {
        color: white;
        background-color: $blue-color;
      }
    }

    &:disabled {
      background-color: $grey-light-color;
    }

    .he-ui-main-button-icon {
      font-size: 2rem;
    }
  }
</style>
